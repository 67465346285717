@font-face {
  font-family: 'MarkPro';
  src: url('../assets/fonts/MarkPro-Bold.woff2') format('woff2'),
      url('../assets/fonts/MarkPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'MarkPro';
  src: url('../assets/fonts/MarkPro-ExtraLight.woff2') format('woff2'),
      url('../assets/fonts/MarkPro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MarkPro';
  src: url('../assets/fonts/MarkPro.woff2') format('woff2'),
      url('../assets/fonts/MarkPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MarkPro';
  src: url('../assets/fonts/MarkPro-Medium.woff2') format('woff2'),
      url('../assets/fonts/MarkPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

html, body {
  height: 100%;
  width: 100%;
  font-family: "MarkPro", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

a, button {
  font-family: "MarkPro", "Roboto", "Helvetica", "Arial", sans-serif !important;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.relative {
  position: relative;
}

div.inline {
  display: inline-block;
}

.bold {
  font-weight: bold;
}

.blue {
  color: #042859;
}

.blue-light {
  color: #2A87FC;
}

.menu-link {
  text-decoration: none;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
}

.flex-row-center > * {
  margin-right: 0.5rem;
  margin-top: auto;
  margin-bottom: auto;
}

.home-link {
  color: white;
}

.loading-context-bloc {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

#global-wait {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  z-index: 9999;
}

.entity-name {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.customer-name {
  margin-left: 1rem;
  text-transform: uppercase; 
}

.icon-profil {
  margin-left: 1rem;
}

.svg-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
  font-size: 24px;
}

.list-field-title .svg-icon, .breadWithIcon .svg-icon, .legende .svg-icon {
  width: 1rem;
  height: 1rem;
}

.breadcrumb {
  list-style: none;
  margin: 0;
  height: 1rem;
  padding: 0;
  color: grey;
}

.breadcrumb li {
  float: left;
  font-size: 0.7rem;
  margin-right: 0.5rem;
}

.breadcrumb li div {
  padding-top: 0.3rem;
  padding-left: 2rem;
  position: relative;
}

.breadcrumb li div.breadWithIcon {
  padding-left: 2rem;
}

.breadcrumb li div.breadWithoutIcon {
  padding-left: 0rem;
}

.breadcrumb li div.breadcrumb-sep {
  padding-left: 0rem;
}

.breadcrumb li svg, .breadcrumb li img {
  position: absolute;
  top: 0;
  left: 0;
}

.breadcrumb a {
  text-decoration: none;
  color: black;
}

.list-field-header {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
  clear: both;
}

.list-field-title {
  display: flex;
  margin: 0.5rem;
  color: grey;
  font-size: 0.8rem;
  align-items: center;
}

.list-field-title svg, .list-field-title img {
  font-size: 16px !important;
  float: left;
  margin-right: 0.5rem;
}

.object-info-line {
  margin: 1rem;
  font-size: 0.7rem;
  color: grey;
}

.read-item-container {
  margin-top: 2rem;
}

.preview-item-container {
  padding: 0.5rem;
  font-size: 0.8rem;
  min-width: 300px;
}

.form-read-field {
  margin-bottom: 1rem;
}

.form-read-field-small {
  margin-bottom: 0;
  font-size: 0.7rem;
}

.small-view .form-read-field {
  margin-bottom: 0.3rem;
}
 
.form-read-field-label {
  color: grey;
}

.form-read-field-value {
  display: inline-block;
}

.form-input-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
}

.table-col-action {
  width: 200px;
}

.embeddedlist-row {
  clear: both;
  margin-bottom: 1rem;
  min-height: 3rem;
  display: flex;
  align-items: flex-end;
}

.embeddedlist-col {
  float: left;
  min-width: 200px;
}


.embeddedlist-col-label {
  color: grey;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  display: block;
}

.embeddedlist-col-value {
  display: block;
}

.form-edit-field {
  margin: 1rem;
  display: inline-block;
}

.table-toolbar-input .form-edit-field {
  min-width: 250px;
  margin: auto;
  margin-bottom: 1rem;
}

.form-edit-field-container {
  margin-left: 1rem;
  margin-right: 1rem;
}

.multi-input-field {
  display: inline-flex;
  flex-direction: row;
}

.multi-input-field .form-edit-field {
  margin: 1rem 1rem 1rem 0.5rem;
}

.multi-input-field .form-edit-field:first-child {
  margin: 1rem 0 1rem 1rem;
}

.page-header {
  margin-bottom: 2rem;
}

.page-footer {
  margin-top: 2rem;
}

.embedded_object-content {
  margin-top: 1rem;
}

.embedded_object-content .form-read-field {
  font-size: 0.8rem;
}

.menu-part-toptitle {
  text-align: center;
  margin-top: 0.5rem;
  background-color: #e0e0e040;
  padding: 0.3rem;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #616161;
  font-size: 0.8rem;
}

.legende {
  font-size: 0.7rem;
  color: grey;
}

.legende-black {
  font-size: 0.7rem;
  color: black;
}

.offline {

}

.state-pastille {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;  
}

.offline .state-pastille, .pastille-default {
  background-color: #e1e1e1;
}

.online .state-pastille, .pastille-success {
  background-color: #B8E986;
}

.wferror .state-pastille, .pastille-error {
  background-color: red;
}

.pastille-warning {
  background-color: orange;
}

.line {
  margin-bottom: 1rem;
}

.subline {
  margin-bottom: 0.3rem;
}

tr.collapsed, tr.collapsed > td {
  height: 0;
  display: none;
}

.raw-part-title {
  font-size: 1rem;
  font-weight: normal;
  text-decoration: underline;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  clear: both;
}

.raw-part-data {
  float: left;
  margin: 0.5rem;
}

.live-content {
  margin-top: 0.8rem;
}

.live-content .legende {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.live-content .form-read-field-label {
  font-size: 0.8rem;
}

.state-field {
  display: flex;
  align-items: center;
}

.state-field .state-pastille {
  margin-right: 0.5rem;
}

.item-read-part-title-bloc {
  margin-top: 1rem;
}

.item-read-part-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.item-read-part-title-text {
  margin-left: 1rem;
}

.toolbar-content {
  position: relative;
  height: 100%;
}

.toolbar-footer {
  position: absolute;
    bottom: 1rem;
    text-align: center;
    width: 100%;
}

.kpi-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kpi-bloc {
  text-align: left;
  font-size: 0.8rem;
}

.kpi-chart-bloc-dashboard {
  height: 400px;
}

.kpi-availability-resume-day {
  height: 60px;
  margin-bottom: 1rem;
}

.kpi-availability-resume-day-light {
  height: 40px;
}

.kpi-availability-resume-day-title {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.kpi-bloc-count-line {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kpi-bloc-count {
  padding: 0.2rem;
  text-align: center;
  background-color: #cac6c62b;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  flex: 1;
  height: 60px;
  display: flex;
  flex-direction: column;
}

.kpi-bloc-count.success, .color-success {
  color: #43af00;
}

.kpi-bloc-count.error, .color-error {
  color: #f50000;
}

.kpi-bloc-count.notstarted {
  color: #909090;
}

.kpi-bloc-count-label {
  font-size: 0.7rem;
  font-weight: normal;
  flex: 1;
}

.kpi-bloc-count-value {
    font-size: 1.2rem;
    flex: 1;
}

.kpi-energy-line {
  padding: 0.2rem;
  text-align: center;
  background-color: #cac6c608;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  flex: 1;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start
}

.kpi-energy-line-bloc {
  padding: 0.2rem;
  color: #0C76FE;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.kpi-energy-line-bloc-label {
  font-size: 0.7rem;
  font-weight: normal;
  color: #757575;
}

.kpi-energy-line-bloc-value {
  font-size: 1.2rem;
}

.kpi-energy-line-bloc-value .unit {
  font-size: 0.8rem;
  display: block;
}

.kpi-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pageContent {
  margin-top: 2rem;
  clear: both;
}

.kpi-chart-bloc-label {
  margin-right: 0.5rem;
  background-color: #cac6c62b;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 0.3rem;
}

.kpi-chart-bloc-chart {
  padding: 1rem;
}

.kpi-content-part-label {
  font-weight: bold;
    font-size: 1.5rem;
    color: #0C76FE;
    background-color: #cac6c608;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
}

.kpi-filter-bloc {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.receptacle-line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.receptacle-bloc {
  
  text-align: center;
  border-radius: 0.3rem;
  font-weight: bold;
  margin-right: 1rem;
  
  color: #909090; 
  
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
}

.nozzle-icon {
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.3rem;
}

.nozzle-icon svg > g > g {
  fill: #cccccc;
}

.receptacle-bloc-nozzle-up {
  background: linear-gradient(#2FA2FC, #1B7FF9);
}

.receptacle-bloc-nozzle-up .nozzle-icon  svg > g > g {
  fill: #ffffff;
}

.state-square {
  padding: 0.2rem;
  text-align: center;
  border-radius: 0.3rem;
  font-weight: bold;
  margin-right: 0.5rem;
  background-color: #cac6c62b;
  color: #909090;
  /*background-color: #D8D8D8;
  color: #ffffff;*/
}

.station-state {
  padding: 0.1rem 0.5rem; 
  text-align: center;
  border-radius: 0.2rem;
  margin-right: 0.5rem;
  background-color: #cac6c62b;
  color: #909090;
  text-transform: uppercase;
  display: inline-block;
}

.station-state-label {
  display: flex;
  align-items: center;
}

.station-state-label > svg {
  font-size: 0.8rem;
  margin-right: 0.3rem;
  height: 1rem;
  width: 1rem;
  fill: #ffffff;
}

.station-state-label > svg g#pushpin {
  fill: #ffffff;
}

.station-state-success {
  background-color: #50E3C2;
  color: #ffffff;
}
 
.station-state-warning {
  background-color: #FF9B63;
  color: #ffffff;
}

.station-state-error {
  background-color: #EA3861;
  color: #ffffff;
}

.receptacle-bloc-nozzleUp {
  border: 2px solid;
  /*border: 3px solid #ffe200;*/
}

.receptacle-bloc-success, .state-square-success {
  /*background: linear-gradient(#1B7FF9, #2FA2FC);*/
  background-color: #b8e98640;
  color: #2c5404;
}

.receptacle-bloc-error, .state-square-error {
  background-color: #ff00002e;
  color: red;
}

.receptacle-bloc-warning, .state-square-warning {
  /*background: linear-gradient(#E26300, #FF9B63);*/
  background-color: #ffa5001a;
  color: orange;
}

.receptacle-badge-nozzle {
  position: absolute;
}

.energy-type {
  background: linear-gradient(#2FA2FC, #1B7FF9);
  padding: 0.3rem;
  color: white;
  font-weight: bold;
  border-radius: 0.3rem;
  font-size: 0.8rem;
  display: inline-block;
}

.energy-type-warning {
  background: linear-gradient(#FF9B63, #E26300); 
}

.energy-type-error {
  background: linear-gradient(#f6f6f5, #f6f6f5);
  color: #ff7373; 
}

.refill-header {
  margin-top: 0.5rem;
  justify-content: space-between;
}

.refill-header, .refill-delivery-resume {
  display: flex;
  flex-direction: row;
}

.refill-delivery-resume > div {
  flex: 1;
}

.dateperiode-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.table-header-cell-content {
  display: flex;
}

.table-filter-content {
  padding: 1rem;
}

.table-filter-choices {
  max-height: 170px;
  overflow-y: scroll;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.station-headline {
  display: flex;
  align-items: center;
}

.station-headline .station-name {
  font-size: 1.1rem;
  font-weight: bold;
  margin-right: 1rem;
  color: #042859;
}

.station-headline .station-nexopus-state {
  justify-self: flex-end;
  margin-left: auto;
}

.station-info-picto {
  height: 14px;
  margin-right: 0.4rem;
}


.station-info-picto-fade {
  opacity: 0.3;
}

.station-info {
  margin-left: 1rem;
  display: flex;
}

.station-info:first-child {
  margin-left: 0;
}
.dispenser {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.busy {
  background-color: rgba(25, 127, 249, 0.04);
}

.txtred {
  color: red;
}

.refill-expiration-bloc {
  padding: 0.5rem 0.8rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 13px;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.refill-expiration-bloc .legende {
  color: black;
}

.refill-expiration-bloc .legende .duration {
  display: inline-block;
  padding: 0 0.4rem;
  background-color: #57a1ff;
  color: white;
  font-weight: bold;
  border-radius: 10px;
  margin-left: 0.1rem;
}

.refill-expiration-bloc-left {
  text-align: left;
  color: #6e6e6e;
}


.refill-expiration-bloc-right {
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refill-expiration-bloc-on {
  background-color: #ececec;
}

.refill-vehicle-bloc {
  display: flex;
  align-items: center;
}

.refill-vehicle-bloc-illustration {
  margin-right: 2rem;
  opacity: 0.3;
}

.refill-vehicle-bloc-illustration-on {
  opacity: 1;
}


.device-infos-container {
  margin-top: 2px;
}

.device-infos {
  display: flex;
    background-color: #ececec;
    padding: 5px 8px;
    border-radius: 10px;
    font-size: 0.9rem;
    align-items: center;
}

.device-infos .check-icon {
  height: 14px;
  width: 19px;
}

.device-infos .check-icon > svg {
  height: 0.6em !important;
  color: #0eb90e !important;
  width: 0.7em !important;
}

.device-infos > img {
  height: 16px;
  margin-right: 0.2rem;
}

.pos-view {
  display: flex;
  align-items: center;
}

.pos-view svg {
  margin-right: 0.5rem;
}

.legende .pos-view svg {
  width: 1rem;
}

.soc-view {
  display: flex;
  align-items: flex-end;
  min-width: 70px;
  font-weight: 500;
}

.soc-view svg {
  margin-left: -5px;
  font-size: 2rem;
}

.small-view {
  font-size: 0.9rem;
}

.small-view .soc-view svg {
  font-size: 1.5rem;
}
.soc-level-high svg {
  color: #50E3C2;
}

.soc-level-medium svg {
  color: #FF9B63;
}

.soc-level-low svg {
  color: #EA3861;
}

.soc-level-unknown svg {
  color: #d4d4d4;
}

.embeddedlist-container {
    display: flex;
    flex-direction: row;
}

.embeddedlist-item {
  margin-right: 1rem;
}
/*
.running-refill-bloc {
  background-color: #ffffff;
  padding: 1rem;
}*/

.loglevel-view {
  padding: 0.1rem 0.5rem;
  font-weight: 500;
  border-radius: 10px;
}

.loglevel-trace {

}

.loglevel-debug {
  background-color: #ececec;
}

.loglevel-info {
  background-color: #57a1ff;
  color: white;
}

.loglevel-analytics {
  background-color: white;
  color: #0eb90e;
}

.loglevel-error {
  background-color: #ffffff;
  color: red;
}

.loglevel-crash {
  background-color: red;
  color: white;
}

.object-error-bloc {
  background-color: #ff00001c;
  color: red;
  margin: 0.5rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  font-weight: 500;
}

.array-obj-value {
  display: block;
}

.array-obj-value:nth-child(n+2) {
  margin-top: 0.5rem;
}

.bloc-container {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
}

.kpi-view-bloc {
  background-color: white;
  /*padding: 1rem;*/
  border-radius: 5px;
}

.kpi-blocs {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
}

.kpi-view-main-title-bloc {
  color: #042859;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
}

.kpi-view-main-title {
  margin-left: 0.2rem;
}

.kpi-view-title {
  color: #042859;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
 
.kpi-view-subtitle {
  color: #A9A9A9;
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}

.kpi-value {
  color: #042859;
  margin-right: 0.2rem;
}
 
.kpi-value-main {
  font-size: 1.7rem;
  font-weight: bold;
}

.kpi-value-second {
  font-size: 1rem;
  font-weight: 500;
}

.kpi-value-help {
  font-size: 0.8rem;
}

.kpi-value-progress {
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0.1rem 0.2rem;
    border-radius: 5px;
    margin-left: 5px;
    color: #616161;
    background-color: #6161611c;
    white-space: nowrap;
}

.kpi-value-progress-positif {
  color: #22B74C;
  background-color: #22b74c1a;
}

.kpi-value-progress-negatif {
  color: red;
  background-color: #ff000021;
}

.kpi-value-progress-dark-positif {
  color: #22B74C;
  background-color: #ecf8ed;
}

.kpi-value-progress-dark-negatif {
  color: red;
  background-color: #ffe4e2;
}

.kpi-view-part:not(:first-child) {
  margin-top: 1rem;
  border-top: 1px solid #00000012;
  padding-top: 0.5rem;
}

.kpi-view-subpart {
  margin-bottom: 1rem;
}

.kpi-view-periode {
  color: grey;
  font-size: 0.8rem;
}

.kpi-view-nodata {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: grey;
}
 
.kpi-view-nodata-small {
  height: 50px;
}