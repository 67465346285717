@font-face {
    font-family: 'MarkPro';
    src: url('../../assets/fonts/MarkPro-Bold.woff2') format('woff2'),
        url('../../assets/fonts/MarkPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MarkPro';
    src: url('../../assets/fonts/MarkPro-ExtraLight.woff2') format('woff2'),
        url('../../assets/fonts/MarkPro-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MarkPro';
    src: url('../../assets/fonts/MarkPro.woff2') format('woff2'),
        url('../../assets/fonts/MarkPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MarkPro';
    src: url('../../assets/fonts/MarkPro-Medium.woff2') format('woff2'),
        url('../../assets/fonts/MarkPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  html, body {
    height: 100%;
    width: 100%;
    font-family: "MarkPro", "Roboto", "Helvetica", "Arial", sans-serif !important;
  }
  
  a, button {
    font-family: "MarkPro", "Roboto", "Helvetica", "Arial", sans-serif !important;
  }

.u2fLogo {
    width: 180px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.u2Container {
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    padding-top: 5rem;
}

.u2f-instruction {
    margin-top: 1rem !important;
    font-weight: bold !important;
    /*animation: blinker 1s linear infinite;*/
}

.u2f-error {
    margin-top: 1rem !important;
    font-weight: bold !important;
    color: red !important;
}

.color-red {
    color: red;
}

.form-title {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.form-error {
    padding: 1rem !important;
    font-weight: bold !important;
    color: red !important;
}

.login-container {
    position: relative;
}

.form-login {
  position: relative;
  z-index: 1;
}

.form-login .u2Container {
    padding-top: 8rem;
}

.bloc-msg {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.success-icon {
    color: green;
}

@keyframes blinker {
    100% {
        opacity: 50%;
    }
}